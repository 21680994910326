import extensions from "./ext/extensions_all";
import GanttFactory from "./factory/gantt_factory";
import * as factoryMethod from "./factory/make_instance_web";
import * as scope from "./utils/global";
// tslint:disable-next-line variable-name
const Gantt = new GanttFactory(factoryMethod, extensions);
const w = scope as any;
w.Gantt = Gantt;
const gantt = w.gantt = Gantt.getGanttInstance();

export default gantt;
export { Gantt, gantt };
